import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import Logo from './logo';
import Arrow from '@components/common/atoms/Arrow';
import { useAuth0 } from '@auth0/auth0-react';
import { AnnotationIcon, CollectionIcon, LogoutIcon, UserCircleIcon } from '@supertakst/ui-common';
import { UserProfileSchema } from '@supertakst/model-common';
import { useUser } from '@components/hooks/userProfileQueries';
import { useRouter } from 'next/router';
import { Conflict, conflictsAtom } from '@store/atoms';
import { useAtomValue } from 'jotai';
import { Portal } from 'react-portal';
import ConflictModal from '@components/common/PageContainer/ConflictModal';
import { useIsMutating } from '@tanstack/react-query';
import FullLoader from '@components/common/FullLoader';

type Props = {
  children: React.ReactNode;
  subHeader?: React.ReactNode;
};

const PageContainer = ({ children, subHeader }: Props) => {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const conflicts = useAtomValue(conflictsAtom);
  const [activeConflict, setActiveConflict] = useState<Conflict>();
  const { logout } = useAuth0();
  const navRef = useRef(null);
  const router = useRouter();
  const {
    query: { id = null, version = null },
  } = router;

  // Show loaders on the following mutations
  const isSavingPart = useIsMutating({ mutationKey: ['savePart'], exact: true, fetching: true });
  const isSavingTilstandsrapport = useIsMutating({
    mutationKey: ['saveTilstandsrapport'],
    exact: true,
    fetching: true,
  });
  const isDuplicatingTilstandsrapport = useIsMutating({
    mutationKey: ['duplicateTilstandsrapport'],
    exact: true,
    fetching: true,
  });
  const isArchivingTilstandsrapport = useIsMutating({
    mutationKey: ['archiveTilstandsrapport'],
    exact: true,
    fetching: true,
  });

  const showFullLoader =
    isSavingPart ||
    isSavingTilstandsrapport ||
    isDuplicatingTilstandsrapport ||
    isArchivingTilstandsrapport;

  // END Show loaders on the following mutations

  useClickAway(navRef, () => {
    if (navOpen) {
      setNavOpen(false);
    }
  });

  useKey(
    'Escape',
    () => {
      if (navOpen) {
        setNavOpen(false);
      }
    },
    undefined,
    [navOpen]
  );

  useEffect(() => {
    if (conflicts.length > 0) {
      setActiveConflict(conflicts[0]);
    } else {
      setActiveConflict(undefined);
    }
  }, [conflicts]);

  const { isInitialLoading: isInitialUserLoading, data: userData } = useUser();
  if (isInitialUserLoading) {
    return null;
  }

  let redirectToProfile = false;
  if (userData) {
    const userValidationResult = UserProfileSchema.validate(userData);
    redirectToProfile = !!userValidationResult.error;
  } else {
    redirectToProfile = true;
  }

  if (redirectToProfile && router.pathname !== '/profil') {
    router.push('/profil');
  }

  return (
    <>
      <section className="bg-bg-color">
        <header>
          <section className="bg-supertakst-blue-900 text-white px-10 py-7 flex justify-between font-medium">
            <Link href="/">
              <a>
                <Logo />
              </a>
            </Link>
            <div ref={navRef}>
              <button onClick={() => setNavOpen(!navOpen)}>
                <span>Meny</span>
                <Arrow direction={navOpen ? 'up' : 'down'} className="ml-3 stroke-white" />
              </button>
              {navOpen && (
                <nav className="absolute top-[90px] right-0 bg-supertakst-blue-900 text-white w-60 font-medium z-50">
                  <ol>
                    <li className="hover:bg-supertakst-green-300 cursor-pointer border-b border-[#1B2467]">
                      <Link href="/">
                        <a className="py-5 px-10 flex items-cente">
                          <CollectionIcon className="[&>path]:stroke-white mr-2 scale-75" />
                          Mine oppdrag
                        </a>
                      </Link>
                    </li>
                    <li className="hover:bg-supertakst-green-300 cursor-pointer border-b border-[#1B2467]">
                      <Link href="/profil">
                        <a className="py-5 px-10 flex items-center">
                          <UserCircleIcon className="[&>path]:stroke-white mr-2 scale-75" />
                          Min profil
                        </a>
                      </Link>
                    </li>
                    <li className="hover:bg-supertakst-green-300 cursor-pointer border-b border-[#1B2467]">
                      <a
                        href={`https://form.typeform.com/to/eTkmUsU3#email=${userData?.email}&user_id=${userData?.id}&product_id=BefaringApp&tilstandsrapport_id=${id}&version=${version}`}
                        target="_blank"
                        className="py-5 px-10 flex items-center"
                        rel="noreferrer"
                      >
                        <AnnotationIcon className="[&>path]:stroke-white mr-2 scale-75" />
                        Gi tilbakemelding
                      </a>
                    </li>
                    <li className="hover:bg-supertakst-green-300 cursor-pointer">
                      <a
                        href="#"
                        onClick={() => logout({ returnTo: window.location.origin })}
                        className="py-5 px-10 flex items-center"
                      >
                        <LogoutIcon className="[&>path]:stroke-white mr-2 scale-75" />
                        Logg ut
                      </a>
                    </li>
                  </ol>
                </nav>
              )}
            </div>
          </section>
        </header>
        {subHeader && subHeader}
        <main className="mx-auto w-full p-4 lg:w-3/4">{children}</main>
        {activeConflict && (
          <Portal>
            <ConflictModal conflict={activeConflict} />
          </Portal>
        )}
      </section>
      {showFullLoader > 0 && <FullLoader />}
    </>
  );
};

export default PageContainer;
