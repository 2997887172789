import format from 'date-fns/format';
import { nb } from 'date-fns/locale';

export const formatDate = (date: string | Date, formatStr: string) => {
  try {
    const d = date instanceof Date ? date : new Date(date);
    return format(d, formatStr, { locale: nb });
  } catch (e) {
    console.error('formatDate errors:', e);
    return null;
  }
};
