import useLockBodyScroll from '@components/hooks/useLockBodyScroll';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: (e?) => void;
};

const Modal = ({ children, onClose, isOpen }: Props) => {
  const modalRef = useRef(null);
  useLockBodyScroll(isOpen);

  useClickAway(modalRef, () => {
    onClose && onClose();
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="backdrop"
          className="fixed z-40 w-screen h-screen inset-0 bg-supertakst-blue-900 bg-opacity-75"
        ></motion.div>
      )}
      {isOpen && (
        <div className="z-40 fixed w-screen h-screen inset-0 flex items-start justify-center">
          <motion.div
            className="mt-[10vh] max-w-[90%] lg:max-w-[60%] w-full bg-white rounded px-6 py-9 drop-shadow-lg"
            key="modal"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            ref={modalRef}
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
