import React from 'react';
import { Conflict, conflictsAtom } from '@store/atoms';
import Header from '@components/common/Header';
import Modal from '@components/common/Modal';
import { formatDate } from '@utils/date';
import { Button } from '@supertakst/ui-common';
import { modelUtils } from '@supertakst/model-common';
import {
  useMutateTilstandsrapport,
  useRemoveTilstandsrapportFromQueryCache,
} from '@components/hooks/tilstandsrapportQueries';
import { deleteTilstandsrapport } from '@utils/localDb';
import { useAtom } from 'jotai';

type Props = {
  conflict: Conflict;
};

const ConflictModal = ({ conflict }: Props) => {
  const removeTilstandsrapportFromQueryCache = useRemoveTilstandsrapportFromQueryCache();
  const [serverHistory] = conflict.serverVersion.updateHistory.slice(-1);
  const [localHistory] = conflict.updateFromApi.updateHistory.slice(-1);
  const serverAddress = modelUtils.getFullAddress(conflict.serverVersion);
  const localAddress = modelUtils.getFullAddress(conflict.updateFromApi);
  const [conflicts, setConflicts] = useAtom(conflictsAtom);

  const { mutate: localVersion } = useMutateTilstandsrapport(
    conflict.updateFromApi.id,
    () => {
      setConflicts(conflicts.filter((c) => c.updateFromApi.id !== conflict.updateFromApi.id));
    },
    null,
    true
  );

  const reportAddress =
    serverAddress === localAddress
      ? serverAddress
      : `Annen enhet: ${serverAddress}, denne enheten: ${localAddress}`;

  const doUseServer = async () => {
    removeTilstandsrapportFromQueryCache(conflict.updateFromApi.id);
    await deleteTilstandsrapport(conflict.updateFromApi.id);
    setConflicts(conflicts.filter((c) => c.serverVersion.id !== conflict.serverVersion.id));
    window.location.reload();
  };

  const doUseLocalVersion = async () => {
    localVersion(conflict.updateFromApi);
  };

  return (
    <Modal isOpen={true}>
      <div className="max-h-[75vh] overflow-y-auto">
        <h2 className="h2 text-center text-supertakst-blue-900">Konflikt ved synkronisering</h2>
        <p className="text-base color-supertakst-blue-900 mt-2">
          Du har oppdatert tilstandsrapporten <strong>{reportAddress}</strong> på en annen enhet
          samtidig som du har gjort endringer på denne enheten. Du må nå ta stilling til om du
          ønsker å beholde versjonen som ligger på denne enheten, eller bruke versjonen som ligger
          på server.
        </p>

        <div className="flex bg-supertakst-gray-50 p-6 mt-8">
          <div className="flex-1">
            <Header as="h4">Denne enheten</Header>
            <p>Sist oppdatert: {formatDate(localHistory!.updatedAt, 'EEEE dd.MM.yyyy H:mm:ss')}</p>
            <p>Operativsystem: {localHistory?.deviceInfo?.os?.name}</p>
            <p>Nettleser: {localHistory?.deviceInfo?.browser?.name}</p>
          </div>
          <div className="flex-1">
            <Header as="h4">Annen enhet</Header>
            <p>Sist oppdatert: {formatDate(serverHistory!.updatedAt, 'EEEE dd.MM.yyyy H:mm:ss')}</p>
            <p>Operativsystem: {serverHistory?.deviceInfo?.os?.name}</p>
            <p>Nettleser: {serverHistory?.deviceInfo?.browser?.name}</p>
          </div>
        </div>

        <div className="flex mt-10">
          <div className="flex-1">
            <Button
              onClick={() => {
                doUseLocalVersion();
              }}
            >
              Bruk endringer fra denne enheten
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={async () => await doUseServer()}>
              Bruk endringer fra annen enhet
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConflictModal;
