import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  archiveTilstandsrapport,
  duplicateTilstandsrapport,
  fetchCompletedTilstandsrapporter,
  fetchOngoingTilstandsrapporter,
  fetchTilstandsrapport,
  OnGoingTilstandsrapporterResponse,
  savePart,
  saveTilstandsrapport,
  searchTilstandsrapporter,
  submitTilstandsrapport,
} from '@api/tilstandsrapport';
import { ErrorResponse, Part, Tilstandsrapport } from '@supertakst/model-common';
import LogRocket from 'logrocket';
import { useAtom } from 'jotai';
import { conflictsAtom } from '@store/atoms';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

export const tilstandsrapportQueryKeys = {
  tilstandsrapportById: (id) => ['tilstandsrapport', id],
  ongoingTilstandsrapporter: () => ['ongoingTilstandsrapporter'],
  completedTilstandsrapporter: () => ['completedTilstandsrapporter'],
  searchedTilstandsrapporter: (search, submitted) => [
    'searchedTilstandsrapporter',
    search,
    submitted,
  ],
};

export function useOngoingTilstandsrapporter(options = {}) {
  return useQuery<OnGoingTilstandsrapporterResponse>(
    tilstandsrapportQueryKeys.ongoingTilstandsrapporter(),
    async () => {
      return await fetchOngoingTilstandsrapporter();
    },
    options
  );
}

export function useCompletedTilstandsrapporter(options = {}) {
  return useQuery<Tilstandsrapport[]>(
    tilstandsrapportQueryKeys.completedTilstandsrapporter(),
    async () => {
      return await fetchCompletedTilstandsrapporter();
    },
    options
  );
}

export function useTilstandsrapportSearch(search: string, submitted: boolean, options = {}) {
  return useQuery<Tilstandsrapport[]>(
    tilstandsrapportQueryKeys.searchedTilstandsrapporter(search, submitted),
    async () => {
      return await searchTilstandsrapporter(search, submitted);
    },
    options
  );
}

export function useTilstandsrapportById(id: number, options = {}) {
  const isSavingTilstandsrapport = useIsMutating({
    mutationKey: ['saveTilstandsrapport'],
    exact: true,
    fetching: true,
  });
  const isSavingPart = useIsMutating({
    mutationKey: ['savePart'],
    exact: true,
    fetching: true,
  });
  const router = useRouter();
  return useQuery(
    tilstandsrapportQueryKeys.tilstandsrapportById(id),
    async () => {
      return await fetchTilstandsrapport(id);
    },
    {
      retry(failureCount, error) {
        const toastId = 'error-useTilstandsrapportById-request';
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.push('/');
          toast.error(
            'Tilstandsrapporten er slettet eller overført til en annen bygningssakkyndig',
            {
              toastId: toastId,
            }
          );
          return false;
        }
        if (failureCount >= 3) {
          router.push('/');
          toast.error('Noe gikk galt ved uthenting av tilstandsrapport, prøv igjen om litt', {
            toastId: toastId,
          });
          return false;
        }
        return true;
      },
      ...options,
      enabled: !isSavingTilstandsrapport && !isSavingPart,
    }
  );
}

export function useMutateTilstandsrapport(
  id,
  onSuccessCb?,
  onErrorCb?,
  overrideVersionConflict = false
) {
  const checkConflicts = useCheckForConflicts();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Tilstandsrapport) => saveTilstandsrapport(id, data, overrideVersionConflict),
    {
      mutationKey: ['saveTilstandsrapport'],
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: tilstandsrapportQueryKeys.tilstandsrapportById(id),
        });
        if (onSuccessCb) {
          onSuccessCb();
        }
      },
      onError: (err: Error) => {
        checkConflicts(err);
        if (onErrorCb) {
          onErrorCb();
        }
        LogRocket.captureException(err, {
          tags: {
            action: 'useMutateTilstandsrapport',
            tilstandsrapportId: id,
          },
        });
      },
    }
  );
}

export function useSubmitTilstandsrapport(tilstandsrapportId, onSuccessCb?, onErrorCb?) {
  const checkConflicts = useCheckForConflicts();
  const removeTilstandsrapportFromQueryCache = useRemoveTilstandsrapportFromQueryCache();

  return useMutation((data: Tilstandsrapport) => submitTilstandsrapport(tilstandsrapportId, data), {
    onSuccess: () => {
      removeTilstandsrapportFromQueryCache(tilstandsrapportId);
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (err: Error) => {
      checkConflicts(err);
      if (onErrorCb) {
        onErrorCb();
      }
      LogRocket.captureException(err, {
        tags: {
          action: 'useSubmitTilstandsrapport',
          tilstandsrapportId: tilstandsrapportId,
        },
      });
    },
  });
}

const useCheckForConflicts = () => {
  const [conflicts, setConflicts] = useAtom(conflictsAtom);
  return (err: Error | AxiosError) => {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        const {
          response: { status, data },
        } = err;
        if (status === 409) {
          const errorData = data as unknown as ErrorResponse;
          setConflicts([...conflicts, errorData.error[0].context]);
        }
      }
    }
  };
};

export function useMutatePart(tilstandsrapportId, partId, onSuccessCb?, onErrorCb?) {
  const queryClient = useQueryClient();
  const checkConflicts = useCheckForConflicts();
  return useMutation((partValues: Part) => savePart(tilstandsrapportId, partId, partValues), {
    mutationKey: ['savePart'],
    onSuccess: () => {
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: tilstandsrapportQueryKeys.tilstandsrapportById(tilstandsrapportId),
      });
    },
    onError: async (err: Error) => {
      checkConflicts(err);
      if (onErrorCb) {
        onErrorCb();
      }
      LogRocket.captureException(err, {
        tags: {
          action: 'useMutatePart',
          tilstandsrapportId: tilstandsrapportId,
          partId: partId,
        },
      });
    },
  });
}

export function useRemoveTilstandsrapportFromQueryCache() {
  const queryClient = useQueryClient();

  return (tilstandsrapportId) => {
    // Remove single entry
    queryClient.invalidateQueries({
      queryKey: tilstandsrapportQueryKeys.tilstandsrapportById(tilstandsrapportId),
    });
    // Remove from onGoing
    queryClient.invalidateQueries({
      queryKey: tilstandsrapportQueryKeys.ongoingTilstandsrapporter(),
    });
    // Remove from archived/done
    queryClient.invalidateQueries({
      queryKey: tilstandsrapportQueryKeys.completedTilstandsrapporter(),
    });
  };
}

export function useArchiveTilstandsrapport(tilstandsrapportId, onSuccessCb?, onErrorCb?) {
  const removeTilstandsrapportFromQueryCache = useRemoveTilstandsrapportFromQueryCache();
  return useMutation(() => archiveTilstandsrapport(tilstandsrapportId), {
    mutationKey: ['archiveTilstandsrapport'],
    onSuccess: () => {
      removeTilstandsrapportFromQueryCache(tilstandsrapportId);
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (err: Error) => {
      if (onErrorCb) {
        onErrorCb();
      }
      LogRocket.captureException(err, {
        tags: {
          action: 'useDeleteTilstandsrapport',
          tilstandsrapportId: tilstandsrapportId,
        },
      });
    },
  });
}

export function useDuplicateTilstandsrapport(tilstandsrapportId, onSuccessCb?, onErrorCb?) {
  const queryClient = useQueryClient();
  return useMutation((befaring: Tilstandsrapport) => duplicateTilstandsrapport(befaring), {
    mutationKey: ['duplicateTilstandsrapport'],
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: tilstandsrapportQueryKeys.ongoingTilstandsrapporter(),
      });
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (err: Error) => {
      if (onErrorCb) {
        onErrorCb();
      }
      LogRocket.captureException(err, {
        tags: {
          action: 'useDuplicateTilstandsrapport',
          tilstandsrapportId: tilstandsrapportId,
        },
      });
    },
  });
}
