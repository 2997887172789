import React from 'react';
import { PulseLoader } from 'react-spinners';

const FullLoader = () => {
  return (
    <div className="fixed inset-0 bg-supertakst-blue-900 bg-opacity-75 z-50 flex justify-center items-center">
      <div className="flex items-center flex-col">
        <PulseLoader color="#fff" size={8} margin={2} />
        <p className="text-white">Lagrer innhold...</p>
      </div>
    </div>
  );
};

export default FullLoader;
