import React, { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  direction?: 'up' | 'right' | 'down' | 'left';
  className?: string;
} & SVGProps<SVGSVGElement>;

const Arrow = ({ direction = 'left', className, ...rest }: Props) => {
  const rotate = {
    up: 'rotate-[90deg]',
    right: 'rotate-[180deg]',
    down: 'rotate-[270deg]',
    left: '',
  };

  return (
    <svg
      className={twMerge('inline stroke-supertakst-blue-900', rotate[direction], className)}
      width={7}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M6 1 2 5l4 4" strokeWidth={1.576} />
    </svg>
  );
};

export default Arrow;
